import React from "react"
import Seo from "../components/Seo/seo"
import Layout from "../components/Layout/layout";
import PageBody from "../components/PageBody/pageBody";
import {graphql} from "gatsby";
import Image from "gatsby-image/withIEPolyfill";
import styled from 'styled-components'
import media from 'styled-media-query'

const SectionWithImage = styled(({imageFluid, children, className}) => (
  <section className={className}>
    <div className="body">
      {children}
    </div>
    <Image className="image" fluid={imageFluid} objectFit="contain" />
  </section>
))`
  display: flex;
  
  ${media.lessThan('medium')`
    display: block;
  `}
  
  > .body {
    width: 70%;
    margin-top: calc(1.78rem - 3.56rem);
    margin-bottom: 1.78rem;
    margin-right: 24px;
    
    ${media.lessThan('medium')`
      width: 100%;
    `}
  }
  
  > .image {
    width: 30%;
    
    ${media.lessThan('medium')`
      width: 100%;
    `}
  }
`

const Services = ({data}) => {
  return (
    <>
      <Seo slug=""/>
      <Layout>
        <PageBody h1="Services">
          <SectionWithImage imageFluid={data.businessLocalePict.childImageSharp.fluid}>
            <h2>Asset Management</h2>
            <p>
              From acquisition to management and ultimately disposition we consider ourselves a one-stop shop for all of our investors’ real estate needs. Whether that is assisting our Asia-based clients in procuring and developing a parcel of land in Japan or scouring the US for our Japan based clients for that perfect ground lease asset, we believe it is our cross-border ability that really sets us apart.  Our collective years of experience in the real estate business has equipped us with a strong network and edge in sourcing deals both locally and abroad.  To date, we have underwritten about 35% of our business in non-Japan with the remaining 65% focused on Japan, a trend we believe will continue given the current circumstances.
            </p>
          </SectionWithImage>
          <section style={{marginTop: -48}}>
            <h2>Financial Advisory / Fund Management</h2>
            <p>
              Coming from a hedge fund background we believe that financial structuring is our other strength. Be it a creative solution providing further financing to close a deal or perhaps a workout, our financial ‘know-how’ truly is an invaluable tool in the real estate space. With our ability to not only source but properly structure opportunities it is only a matter of time until we enter the fund management business. Our recent acquisition of Japan travel agent Free Plus
              (<a href="https://www.freeplus.co.jp/en" target="_blank" rel="noopener nofollow noreferrer">https://www.freeplus.co.jp/en</a>)
              fully highlights our capabilities and we believe will provide us the launch pad we need to foray into the fund business.
            </p>
          </section>
        </PageBody>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ServicesIndexQuery {
    businessLocalePict: file(relativePath: { eq: "business-locale.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800, maxHeight: 1800, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

// export default Services
export default () => {
    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}
